.home {
  .hero-section {
    position: relative;

    .carousel-slide {
      position: relative;
      min-height: 460px;
      .text-content {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        padding: 50px 50px;
        z-index: 1;
        h1 {
          font-size: 70px;

          font-weight: 700;
          font-family: martel;
        }
        p {
          font-size: 28px;
          font-family: poppins;
        }
      }
      .img-holder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
    // .carousel-slide.orange {
    //   background-image: url("../images/products/R-Series Facing Bricks.jpeg");
    // }
    // .carousel-slide.gray {
    //   background-image: url("../images/products/Twisting Ropes.jpeg");
    // }
    // .carousel-slide.blue {
    //   background-image: url("../images/products/Oasis Stone.jpeg");
    // }
    // .carousel-slide.beige {
    //   background-image: url("../images/products/Cube Cut.jpg");
    // }
    // .carousel-slide::after {
    //   content: "";
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   top: 0;
    //   left: 0;
    // }
    // .carousel-slide.orange::after {
    //   background-color: #c15638c6;
    // }
    // .carousel-slide.gray::after {
    //   background-color: #282727e6;
    // }
    // .carousel-slide.blue::after {
    //   background-color: #212463c6;
    // }
    // .carousel-slide.beige::after {
    //   background-color: #635621c6;
    // }

    .carousel-indicators button {
      border: 1px solid black;
      box-shadow: 1px 0px 2px 2px rgba(0, 0, 0, 0.384);
      height: 15px;
      width: 15px;
      border-radius: 50%;
    }
    .support-word {
      position: absolute;
      bottom: -22px;
      right: 100px;
      border-radius: 12px 12px 0px 0px;
      background: #f5f5f5;
      box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.23),
        0px 10px 20px 0px rgba(0, 0, 0, 0.19);
      padding: 12px 15px;
      font-weight: 700;
      text-transform: capitalize;
    }
  }
  .about-us {
    position: relative;
    .overlay {
      position: absolute;
      right: 100px;
      top: 10px;
      width: 200px;
      z-index: -1;
    }
    .text-content {
      h1 {
        font-size: 32px;
        color: #c15538;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .whatMake {
    position: relative;
    overflow: hidden;
    .overlay {
      position: absolute;
      width: 150px;
      right: 0;
      top: -70px;
      background-color: #c15538;
      opacity: 0.6;
      border-radius: 50%;
      filter: blur(50px);
      animation: MoveOverlay 4s forwards;
    }
    .header {
      .line {
        height: 5px;
        width: 140px;
        background-color: #c15538;
        position: relative;
        top: -20px;
      }
    }
    .header-holder {
      p {
        color: rgb(231, 231, 231);
        font-size: 20px;
      }
    }
  }
  .panels-section {
    position: relative;
    background-color: var(--dark-secondary-color) !important;
    .overlay {
      position: absolute;
      left: 100px;
      top: 10px;
      z-index: -1;
      width: 200px;
    }
  }
  .our-products {
    background-image: url("../images/products-bg.png");
  }
}
.contact-us {
  background-image: url("../images/contact-us.png");
  .distributor {
    .radio-buttons-container {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    .radio-button {
      display: inline-block;
      position: relative;
      cursor: pointer;
    }

    .radio-button__input {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
    }

    .radio-button__label {
      display: inline-block;
      padding-left: 30px;
      margin-bottom: 10px;
      position: relative;
      font-size: 16px;
      color: rgb(231, 231, 231);
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    }

    .radio-button__custom {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #555;
      transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    }

    .radio-button__input:checked + .radio-button__label .radio-button__custom {
      transform: translateY(-50%) scale(0.9);
      border: 5px solid #c15538;
      color: #c15538;
    }

    .radio-button__input:checked + .radio-button__label {
      color: #c15538;
    }

    .radio-button__label:hover .radio-button__custom {
      transform: translateY(-50%) scale(1.2);
      border-color: #c15538;
      box-shadow: 0 0 10px #c1553880;
    }
  }
  .contact-form {
    background-color: var(--dark-secondary-color);
    padding: 30px 80px 30px 40px !important;
    border-radius: 20px;
    .input-holder {
      position: relative;
      width: 100%;
      label {
        position: absolute;
        top: 10px;
        color: rgb(231, 231, 231);
        transition: 0.3s;
        font-weight: 500;
      }
      input {
        all: unset;
        width: 100%;
        border-bottom: 2px solid #543226;
        padding: 10px;
        margin-bottom: 30px;
        transition: 0.3s;
        color: rgb(231, 231, 231);
      }
      input:focus {
        border-bottom: 2px solid #c15538;
      }
      input:focus + label {
        top: -10px;
        font-size: 12px;
        color: #c15538;
      }
      input:valid + label {
        top: -10px;
        font-size: 12px;
        color: #c15538;
      }
      textarea {
        all: unset;
        width: 100%;
        border-bottom: 3px solid #543226;
        padding: 10px;
        margin-bottom: 30px;
        transition: 0.3s;
        color: rgb(231, 231, 231);
      }
      textarea + label {
        top: -10px;
        font-size: 12px;
        color: #c15538;
      }
    }
  }
  .any-Q {
    padding: 40px 30px;
    background-color: #c15538;
    border-radius: 10px;
    margin-left: -30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: poppins;
    p {
      color: white;
      font-size: 16px;
      font-weight: 600;
    }
    h1 {
      font-size: 32px;
      font-weight: 700;
      color: white;
      margin-bottom: 50px;
    }
    .card-holder {
      background-color: white;
      width: 100%;
      border-radius: 5px 20px 20px 5px;
      margin: 10px 0;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 15px;
      h3 {
        font-size: 20px;
        color: #c15538;
        margin: 0;
      }
      h4 {
        font-size: 15px;
      }
    }
  }
}
@media (max-width: 991px) {
  .hero-section {
    .carousel-slide {
      .text-content {
        padding: 40px 10px 0 10px !important;
        h1 {
          font-size: 50px !important;
        }
      }
      .img-holder {
        margin: 0 auto;
        width: 80%;
      }
    }
  }
}

@keyframes MoveOverlay {
  0% {
    right: 0px;
    width: 50px;
    rotate: 0deg;
  }

  100% {
    width: 200px;
    right: 44%;
    transform: translateX(-50%);
    rotate: 90deg;
    top: 10px;
    filter: blur(100px);
  }
}
