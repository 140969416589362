.gallery {
  .gallery-title {
    color: #c15538;
    display: flex;
    justify-content: center;
    font-size: 40px;
    font-family: poppins;
    font-weight: 800;
  }

  // for Interior Photos
  .image-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }

  .gallery-item {
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    height: 200px; /* Adjust the fixed height as needed */
  }

  .gallery-item:hover {
    transform: scale(1.05);
  }

  .gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  // for Exterior Photos
  .image-grid {
    column-gap: 20px;
    margin-top: 20px;
    column-count: 3; /* Adjust the number of columns as needed */
  }

  .grid-item {
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    margin-bottom: 20px;
  }

  .grid-item:hover {
    transform: scale(1.05);
  }

  .grid-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  ////////////////////////////////////
  .example-gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  .example-item {
    // flex: 1;
    text-align: center;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    img {
      max-width: 100%;
      max-height: 300px;
      object-fit: contain;
      margin-bottom: 10px;
    }
  }
}
