.collapse {
  border-bottom: 2px solid #c1553870;
  padding: 10px;
  width: 100%;
  overflow: hidden;
  transition: 0.3s;
}
.collapse:hover {
  background-color: rgba(193, 86, 56, 0.09);
}
.collapseItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.collapseIcon {
  display: flex;
  place-content: center;
  position: relative;
  background-color: #c15638;
  color: white;
  width: 40px;
  aspect-ratio: 1/1;
  text-align: center;
}
.collapseIcon span {
  font-size: 30px;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  transform: translateY(-54%);
}
.collapse h2 {
  color: white;

  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
}

.collapseDescription {
  max-height: 0;
  color: white;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  margin-top: 0px;
  opacity: 0;
  transition: 0.4s;
}
.collapse.open .collapseDescription {
  max-height: 120px;
  opacity: 1;
  margin-top: 10px;
}
.collapse.open h2 {
  color: #c15538;
}
@media (max-width: 768px) {
  .collapse.open .collapseDescription {
    max-height: 170px;
    opacity: 1;
    margin-top: 10px;
  }
}
@media (max-width: 523px) {
  .collapse.open .collapseDescription {
    max-height: 200px;
    opacity: 1;
    margin-top: 10px;
  }
}
@media (max-width: 445px) {
  .collapse.open .collapseDescription {
    max-height: 230px;
    opacity: 1;
    margin-top: 10px;
  }
  .collapse h2 {
    font-size: 20px;
  }
}
