.holder {
  padding: 100px 0;
  text-align: center;
  background-image: url("../assests/images/bg-hero.png");
}
.holder h1 {
  font-size: 40px;
  font-weight: 700;
  font-family: "Cairo", system-ui;
  color: white;
}
