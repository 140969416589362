.about-us {
  .text-content {
    h1 {
      font-size: 32px;
      color: #c15538;
    }
    p {
      font-size: 20px;
    }
  }
  .our-products {
    background-image: url("../images/products-bg.png");
    margin-top: 100px !important;
    .title {
      width: 85%;
      margin: 30px auto;
      font-size: 28px;
      font-weight: 400;

      line-height: 42px;
    }
  }
  .ourCards {
    margin-top: 50px;
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-direction: row;
    flex-wrap: wrap;
    padding-block: 40px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .ourCard {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      text-align: center;
      border-radius: 10px;
      border: 1px solid #000;
      background: #f6e6e1;
      box-shadow: 0px 1px 7.5px 4px rgba(0, 0, 0, 0.2);

      padding: 40px;
      transition: all 0.3s;

      img {
        max-width: 100px;
        margin-bottom: 10px;
      }
      h1 {
        color: #c15638;
        font-size: 32px;
        transition: all 0.3s;
      }
      p {
      }
    }
    .ourCard:hover {
      background-color: #c15638;
      color: white;
      h1 {
        color: white;
      }
      img {
        filter: brightness(100);
      }
    }
  }
  .our-vision {
    position: relative;
    .overlay {
      position: absolute;
      width: 150px;
      z-index: -1;
      top: 30px;
      left: 50px;
    }
  }

  .ourValues {
    background-color: rgba(193, 85, 56, 0.15);
    padding: 50px 0;

    .valueContent {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
    }
    .header-holder {
      margin-top: -30px;
      .header {
        h1 {
          color: #000;

          font-size: 44px;
          font-style: normal;
          font-weight: 600;
          line-height: 62px;
        }
        .line {
          width: 157.571px;
          height: 5px;
          background-color: #c15538;
        }
      }
    }
  }
}
