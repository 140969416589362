@use "./assests/scss/home.scss";
@use "./assests/scss/products.scss";
@use "./assests/scss/gallery.scss";
@use "./assests/scss/abouts.scss";
@use "./assests/scss/IntallationGuide.scss";
@use "./assests/scss/utility.scss";

@import url("https://fonts.googleapis.com/css2?family=Martel:wght@400;600;700;800&family=Playwrite+DE+Grund:wght@300;400;500;600;700;800&family=Cairo:wght@300;400;500;600;700;800&display=swap");
$main-color: #c15538;

:root {
  --main-color: $main-color;
  --dark-secondary-color: #0b0c0e;
}

a {
  text-decoration: none;
}
body {
  background-color: #070708 !important;

  font-family: "Cairo", system-ui;
}
.btn-one-holder {
  padding: 10px 0;
  width: 250px;
  border-radius: 15px;
  font-size: 18px;
  border: 2px solid white;
  background-color: white;
  margin-bottom: 20px;
  text-transform: capitalize;
  font-weight: 700;
  transition: 0.3s;
  cursor: pointer;
  a {
    color: $main-color !important;
    display: block;
    width: 100%;
    transition: 0.3s;
  }
}
.btn-one-holder:hover {
  border: 2px solid white;
  background-color: transparent;
  a {
    color: white !important;
  }
}
