.holder {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header {
  font-size: 18px;
  color: rgb(231, 231, 231);
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: "Playwrite DE Grund", cursive;
  line-height: 45px;
  z-index: 10;
  position: relative;
  text-align: center;
  @media (min-width: 991px) {
    font-size: 28px;
  }
}
.line {
  margin-top: 5px;
  height: 3px;
  width: 180px;
  background-color: #c15538;
  border-radius: 100vw;
}
