.products {
  .tab-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-block: 20px;
    button {
      outline: none;
      box-shadow: 0 0 6px 0px #c1563870;
      border: 1px solid #c15538;
      background-color: transparent;
      padding: 10px 20px;
      border-radius: 10px;
      font-size: 700;

      transition: all 0.2s;
      color: white;
    }
    .active {
      background-color: #c15538;
      color: white;
      box-shadow: none;
    }
  }
  .tab-content {
    // background-color: #c15538;
  }
  .characteristics {
    .chart-head {
      display: flex;
      justify-content: center;
    }
    .chart-items {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
      gap: 20px;
      margin-top: 20px;
      padding-block: 50px;
    }
    .chart-item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
      border-radius: 8px;
      border: 1px solid #c15538;
      color: rgb(235, 235, 235);
      transition: 0.3s all;
      //   border: 0px dotted #c15538;
      padding-block: 15px;
      cursor: pointer;
    }
    .chart-item:hover {
      transform: scale(1.05);
      //   border: 1px dotted #c15538;
      box-shadow: 0px 0px 14px 1px #c156385d;
    }
    .chart-icon {
      margin-bottom: 10px;
      width: 53px;
    }
    .chart-title {
      font-size: 20px;
    }
  }
}
.catalog-table th,
td {
  padding: 7px;
}
.catalog-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
  overflow: scroll;
}

.catalog-table th,
.catalog-table td {
  border: 1px solid #ddd;
  padding: 12px;
}

.catalog-table thead {
  background-color: #c15538;
  color: white;
}

.catalog-table thead th {
  text-align: center;
  color: white;
}

.catalog-table tbody tr {
  background-color: #f9f9f9;
}

.catalog-table tbody td {
  text-align: center;
}

.catalog-table th[colspan] {
  text-align: center;
}

.catalog-table tbody tr td {
  transition: background-color 0.3s;
}

.catalog-table tbody tr:hover td {
  background-color: #f1f1f1;
}

.text-center {
  text-align: center;
}
.catalog-product {
  .show-catalog-btn {
    background-color: #c15538;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 600;
    width: fit-content;
    margin: 20px auto;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    img {
      width: 28px;
      -webkit-filter: invert(100%); /* Safari/Chrome */
      filter: invert(100%) drop-shadow(0px 0px 0px white);

      margin-left: 10px;
    }
  }
  .scrollable-div {
    /* Adjust height as needed */
    overflow: scroll; /* Enable vertical scrolling */
    scrollbar-width: thin; /* "auto" or "thin" for Firefox */
    scrollbar-color: #c15538 #f1f1f1; /* thumb and track color */
  }

  .scrollable-div::-webkit-scrollbar {
    width: 8px; /* width of the scrollbar */
  }

  .scrollable-div::-webkit-scrollbar-track {
    background: #f1f1f1; /* color of the track */
  }

  .scrollable-div::-webkit-scrollbar-thumb {
    background-color: #c15538; /* color of the thumb */
    border-radius: 4px; /* roundness of the thumb */
  }

  .scrollable-div::-webkit-scrollbar-thumb:hover {
    background-color: #a93c26; /* color of the thumb on hover */
  }
}
