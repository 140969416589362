.whatCard {
  border: 1px solid #c15538;
  padding: 20px;
  height: 100%;
  border-radius: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(5, 5, 5);
  opacity: 0.9;
  position: relative;
  top: 0;
  transition: all 0.3s;

  &:hover {
    top: -10px;
    opacity: 1;
    .img-holder {
      top: -50px;
      scale: 1.7;
    }
    .content {
      font-size: 20px;
    }
    .header {
      font-size: 28px;
    }
  }
  .img-holder {
    background-color: #c15538;
    position: relative;
    width: 57px;
    height: 57px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 1px #c156385d;
    transition: 0.3s;
    top: 0;
    scale: 1;

    img {
      width: 30px;
    }
  }

  .header {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 15px;
    color: #c15538;
    scale: 1;
    transition: 0.3s;
  }
  .content {
    color: rgb(231, 231, 231);
    font-size: 20px;
    scale: 1;
    transition: 0.3s;
  }
}
.artical-card {
  background-color: #242424;
  padding: 20px;
  padding-bottom: 20px;
  height: 100%;
  flex-direction: column;
  display: flex;
  border-radius: 8px;
  text-align: center;
  position: relative;
  box-shadow: 0 0 0px 0px #c1563880;
  top: 0;
  transition: 0.3s;
  &:hover {
    top: -5px;
    .img-holder {
      img {
        scale: 1.2;
      }
    }
  }
  .img-holder {
    overflow: hidden;
    height: 300px;
    border-radius: 9px;
    img {
      transition: 0.3s;
      scale: 1;
    }
  }
  .header {
    font-size: 20px;
    font-weight: 700;
    margin: 10px 0;
    color: #d8d6d6;
  }
  .content {
    color: #d8d6d6;
  }
  a {
    font-size: 24px;
    color: #c15538;
  }
}
.artical-card:hover {
  background-color: #c15538;
  transition: 0.3s;
  box-shadow: 0 0 10px 3px #c1563844;
  * {
    color: white;
    transition: 0.3s;
  }
}
.product-card {
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(20, 20, 20);
  box-shadow: 0px 6px 6px -1px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 8px;
  transition: 0.3s;
  &:hover::before {
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .img-holder {
    width: 100%;
    overflow: hidden;
    height: 220px;
    border-radius: 15px;
    position: relative;
    z-index: 1;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .header {
    font-size: 24px;
    color: rgb(231, 231, 231);
    text-align: center;
    margin: 15px 0;
    transition: 0.3s;
    font-weight: 600;
    position: relative;
    z-index: 1;
  }
  .line {
    background-color: #c15538;
    height: 2px;
    width: 100%;
    margin-bottom: 15px;
    transition: 0.3s;
  }
  .info {
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    transition: 0.3s;
    color: rgb(231, 231, 231);
    position: relative;
    z-index: 1;
  }
}
.product-card::before {
  content: "";
  position: absolute;
  top: 0px;
  height: 2px;
  width: 70%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #c15538;
  transition: width 0.1s, height 0.5s;
}
.product-card.hover {
  margin: auto 20px;
  .info {
    padding-bottom: 30px;
    padding-top: 20px;
    font-size: 18px;
  }
}
.product-card.hover:hover {
  background-color: #c15538;
  .header {
    color: white;
  }
  .line {
    background-color: white;
  }
  .info {
    color: white;
  }
}
.view-more {
  display: flex;
  justify-content: center;
  &:hover {
    a,
    button {
      background-color: #0b0c0e;
    }
  }
}
.view-more a {
  border-radius: 8px;
  width: 250px;
  display: block;
  text-align: center;
  padding: 10px 0;
  color: white;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: capitalize;
  background-color: #c15538;
  border: 1px solid #c15538;
  transition: 0.3s;
}
.view-more button {
  border-radius: 8px !important;
  letter-spacing: 2px;
  all: unset;
  width: 250px;
  display: block;
  text-align: center;
  padding: 10px 0;
  color: white;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #c15538;
  border: 1px solid #c15538;
  transition: 0.3s;
  cursor: pointer;
}

.carousel-holder {
  max-height: 520px;
  margin: 0 10px;
}
.navbar-holder {
  nav {
    background-color: #0b0c0e !important;
  }
}
.top-header {
  border-bottom: 1px solid rgba(221, 185, 168, 0.744);
  background-color: #0b0c0e;
  .mail-icon {
    filter: brightness(0) saturate(100%) invert(35%) sepia(33%) saturate(1291%)
      hue-rotate(328deg) brightness(106%) contrast(87%);
  }
  .right-side {
    .social-icons {
      border-right: 2px solid black;
      border-left: 2px solid black;
      padding: 5px 30px;
      margin: 0 30px;
      display: flex;
      gap: 20px;
      a {
        display: block;
        width: 20px;
      }
    }
    .language-holder {
      img {
        width: 45px;
        margin-bottom: -6px;
        margin-right: 30px;
      }
    }
    .phone-holder {
      align-items: center;

      div {
        width: 30px;
        img {
          width: 100%;
        }
      }
      p {
        margin-left: 10px !important;
        color: rgb(231, 231, 231) !important;
        font-weight: 600;
        margin-bottom: 0 !important;
      }
    }
  }
}

.country-holder {
  margin: 40px 0;

  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  color: black;
  .img-holder {
    width: 200px;
    border: 2px solid rgb(176, 68, 0);
    border-radius: 4px;
    img {
      width: 100%;
    }
  }
  p {
    margin: 10px 0;
    font-size: 22px;
    font-weight: 600;
  }
  a {
    font-size: 20px;
    font-weight: 600;
  }
}

@media (max-width: 991px) {
  .top-header > div {
    align-items: center;
    justify-content: center !important;
  }
}
.nav-link {
  font-weight: 600;
  color: rgb(231, 231, 231) !important;
  font-size: 15px;
  margin-left: 7px;
  letter-spacing: 1px;
  transition: 0.3s;
  position: relative;
  &::after {
    position: absolute;
    width: 0%;
    height: 1.3px;
    background-color: #c15538;
    left: 50%;
    transition: 0.3s;

    bottom: 0;
    content: "";
  }
  &::before {
    position: absolute;
    width: 0%;
    height: 1.3px;
    background-color: #c15538;
    right: 50%;
    transition: 0.3s;

    bottom: 0;
    content: "";
  }
  &:hover {
    color: #c15538 !important;
  }
  &:hover:after,
  &:hover:before {
    width: 50%;
  }
}
.slogan {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  font-family: "Playwrite DE Grund", cursive;
  padding: 15px 0;
  margin: 0;
  border-top: 1px solid black;
  color: #fcfcfc;
  background-color: #543226;
  padding-bottom: 20px;
  @media (min-width: 576px) {
    font-size: 24px;
  }
}
.pdf-holder {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c15638be;
  .close {
    position: absolute;
    top: 15px;
    right: 40px;
    background-color: white;
    border-radius: 50%;
    padding: 5px 12px;
    font-weight: 700;
    cursor: pointer;
  }
}
.filter-primary {
  filter: brightness(0) saturate(100%) invert(35%) sepia(33%) saturate(1291%)
    hue-rotate(328deg) brightness(106%) contrast(87%);
}
