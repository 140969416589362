.intallationGuide {
  .comingSoon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 0;
    text-align: center;
    background-image: url('../../assests/images/bg-hero.png');
    height: calc(100vh - 190px);
  }

  .comingSoon h1 {
    font-size: 50px;
    color: #ffffff;
    opacity: 0;
    transform: translateY(-20px);
    animation: fadeUp 1s forwards 0.3s;
    position: relative;
  }
  .comingSoon h1::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 20px;
  }
  .comingSoon p {
    margin-top: 10px;
    font-size: 19px;
    color: white;
    animation: fadeIn 1s backwards 0.3s;
  }

  @keyframes fadeUp {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
